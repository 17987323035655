<template>
    <LeaveOfAbsence clientCode="DISCOVER"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/Discover/discover_variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "DiscoverLeaveOfAbsence",
};
</script>
<style lang="scss" scoped src="@/assets/css/client/Discover/discoverloa.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Discover/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Discover/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Discover/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Discover/feedback_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Discover/send_email_modal.scss"></style>